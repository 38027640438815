import puzzle from '../image/puzzle.png'
import { useTranslation } from 'react-i18next'

export const Antifraud:React.FC=()=>{
  const { t } = useTranslation()
  return <div id="target5">
    
          <div className="company__mission">
            <div>
              <img src={puzzle} alt="no" className="company__puzzle-img" />
              <div className="company__info-pos2">
                <div className="company__info2">
                  <h1> {t('We_keep_you_safe')}</h1>
                  <div className="company__fraud">
                    <p>{t('Anti_Fraud_m')} </p>
                    <p>{t('Laundering')}</p>
                    <div className="company__texts">{t('text_dot')}</div>
                  </div>
                  <div className="company__text-info">{t('puzzle_text')}</div>
                </div>
              </div>
            </div>
          </div>
  </div>
}