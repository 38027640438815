import { useTranslation } from "react-i18next"
import coins from '../image/coins.png'

export const About:React.FC=()=>{
  const { t } = useTranslation()
  return <div>
     <div id="target4">
          <div>
            <img src={coins} alt="no" className="company__currency-img" />
          </div>
          <div className="company__info-pos">
            <div className="company__info">
              <h1>{t('About_us_UP')}</h1>
              <div>
                <div className="company__texts">{t('text_dot')}</div>
              </div>
              <div className="company__text-inform">{t('company_text')}</div>
            </div>
          </div>
          <div className="company__text-mob1"></div>
        </div>
  </div>
}