import topmoney from '../image/icons/topmoney.svg'
import fb from '../image/icons/fb.svg'
import loc from '../image/icons/locationIcon.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import { action } from '../models/logincontext'
import { Cookie } from './Cookiepage'

const Footer: React.FC = () => {
  const { setState } = UseRecommendedLoginContext()
  const { t } = useTranslation()

  return (
    <div className="footer">
      <div className="footer__menu">
        <div className="footer__card-top-money">
          <div>
            <img
              className="footer__top-money-img"
              src={topmoney}
              alt="noimage"
            />
          </div>

          <div>{t('footer_info_tex')}</div>
        </div>
        <div className="footer__services-info">
          <div className="footer__services">
            <div>
              <p className="footer__services-title"> {t('Services')}</p>
            </div>
            <div>
              <Link
                to="/services"
                onClick={() => setState({ type: action.id, id: 'target1' })}
              >
                {t('Global-transfers')}
              </Link>
            </div>
            <div>
              <Link
                to="/services"
                onClick={() => setState({ type: action.id, id: 'target2' })}
              >
                {t('Currency_Exchange')}
              </Link>
            </div>
            <div>
              <Link
                to="/services"
                onClick={() => setState({ type: action.id, id: 'target3' })}
              >
                {t('Top_Money_App')}
              </Link>
            </div>
            <div className="footer__path">
              <img src={loc} alt="no" />
              <a
                rel="noreferrer"
                href="https://www.google.com/maps/place/King+George+1,+Tel+Aviv-Yafo,+%D0%98%D0%B7%D1%80%D0%B0%D0%B8%D0%BB%D1%8C/@32.0700336,34.7684242,17z/data=!3m1!4b1!4m5!3m4!1s0x151d4c816552705f:0x1005a53c1e2cd45e!8m2!3d32.0700336!4d34.7706129"
                target="_blank"
              >
                {t('path_1')}
              </a>
            </div>
          </div>
          <div className="footer__company">
            <div>
              <p className="footer__services-title"> {t('Company')}</p>
            </div>
            <div>
              <Link
                to="/company"
                onClick={() => setState({ type: action.id, id: 'target4' })}
              >
                {t('About_US')}
              </Link>
            </div>
            <div>
              <Link
                to="/company"
                onClick={() => setState({ type: action.id, id: 'target5' })}
              >
                {t('Anti_Fraud')}
              </Link>
            </div>
            <div>
              <Link to="/privacy">{t('Privecy_Policy')}</Link>
            </div>
            <div>
              <Link to="/cookie">{t('Cookie_Policy')}</Link>
            </div>
            <div>
              <Link to="/contact">{t('header_menu_contact_us')}</Link>
            </div>
            <div className="footer__path2">
              <img src={loc} alt="no" />

              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.google.com/maps/place/Levinski+102,+Tel+Aviv-Yafo,+%D0%98%D0%B7%D1%80%D0%B0%D0%B8%D0%BB%D1%8C/@32.0565372,34.7758639,16.5z/data=!4m5!3m4!1s0x151d4b63e24b04b7:0xc65ab95e89a4d334!8m2!3d32.0570555!4d34.778647"
              >
                {t('path_2')}
              </a>
            </div>
          </div>
          <div className="footer__social-media">
            <div className="footer__services-title"> {t('social')}</div>
            <div className="footer__icons">
              <a
                href="https://www.facebook.com/Top-Money-101613878176243/"
                rel="noreferrer"
                target="_blank"
              >
                <img src={fb} alt="no" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__cookie-pos">
        <Cookie />
      </div>
    </div>
  )
}

export default Footer
