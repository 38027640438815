import { useForm } from 'react-hook-form'
import { fetchmessage } from '../api/Dal'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import { action } from '../models/logincontext'
import ClipLoader from 'react-spinners/ClipLoader'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/react'

const override = css`
  position: absolute;
  left: 35%;
`
export const Contactform = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: '',
      fullName: '',
      message: '',
      subject: '',
      phone: '',
    },
  })
  const { t } = useTranslation()
  const onSubmit = async (data: any) => {
    await handlerclick(data)
  }
  const { State, setState } = UseRecommendedLoginContext()
  const handlerclick = async (data: any) => {
    try {
      setState({ type: action.isLoading, isLoading: true })
      await fetchmessage(data)
      setState({ type: action.isLoading, isLoading: false })
      setState({ type: action.isAuth, isAuth: true })
    } catch (e) {
      setState({ type: action.isLoading, isLoading: false })
      console.log(e)
    }
  }
  return (
    <div className="contact">
      {State.isLoading&&<div className='contact__spinner-shadow'></div>} 
      <h1 className="contact__question">{t('havea_question')}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            maxLength={80}
            className="contact__input-all"
            {...register('fullName', {
               required: true,
               maxLength: 100 ,
               pattern:{
                 value:/^[a-zA-Z]*$/,
                 message: 'Entered value does not match name format',
               }
              })}
            placeholder='*Full Name'
          />
          {errors.fullName && errors.fullName.type === 'required' && (
            <p className="contact__onblur-m">This field is required</p>
          )}
          {errors.fullName && errors.fullName.type === 'maxLength' && (
            <p className="contact__onblur-m">Max length is 100</p>
          )}
          {errors.fullName && errors.fullName.type === 'pattern' && (
            <p className="contact__onblur-m">{errors.fullName.message}</p>
          )}
        </div>

        <div>
          <input
            maxLength={80}
            className="contact__input-all"
            {...register('email', {
              required: true,
              pattern: {
                value: /\S+@\S+.\S+/,
                message: 'Entered value does not match email format',
              },
            })}
            placeholder={'*Email'}
          />

          {errors.email && errors.email.type === 'required' && (
            <p className="contact__onblur-m">This field is required</p>
          )}
          {errors.email && errors.email.type === 'pattern' && (
            <p className="contact__onblur-m">{errors.email.message}</p>
          )}
        </div>
        <div>
          <input
            maxLength={80}
            className="contact__input-all"
            {...register('phone', { 
              required: false,
               maxLength: 80,
               pattern:{
                value:/^[0-9]*$/,
                message: 'Field should only contain numbers',
              }
               })}
            placeholder={'Phone No'}
          />
           {errors.phone && errors.phone.type === 'pattern' && (
            <p className="contact__onblur-m">{errors.phone.message}</p>
          )}
        </div>
        <div>
          <input
            maxLength={80}
            className="contact__input-all"
            {...register('subject', { required: true, maxLength: 80 })}
            placeholder={'*Subject'}
          />
          {errors.subject && errors.subject.type === 'required' && (
            <p className="contact__onblur-m">This field is required</p>
          )}
          {errors.subject && errors.subject.type === 'maxLength' && (
            <p className="contact__onblur-m">Max length is 100</p>
          )}
        </div>
        <div>
          <textarea
            maxLength={2000}
            className="contact__input-mesage"
            {...register('message', { required: true, maxLength: 2000 })}
            placeholder={'*Message'}
          />
          {errors.message && errors.message.type === 'required' && (
            <p className="contact__onblur-m">This field is required</p>
          )}
          {errors.message && errors.message.type === 'maxLength' && (
            <p className="contact__onblur-m">Max length is 2000</p>
          )}
        </div>  
        
        <div className="contact__fakebutton-pos">
       
          {State.isLoading && (
            <div className="contact__spinner">
              
              <ClipLoader
                css={override}
                color={'#e85f00'}
                loading={true}
                size={90}
              />
            </div>
          )}
          <button disabled={State.isLoading} className="button" type={'submit'}>
            {' '}
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  )
}
