import { About } from './About'
import { Mission } from './Mission'
import { Antifraud } from './Antifraud'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import { useRef, useEffect } from 'react'
import { action } from '../models/logincontext'
import { animateScroll as scroll } from 'react-scroll'

export const Company: React.FC = () => {
  const { State, setState } = UseRecommendedLoginContext()
  const copnayref = useRef(null)
  let id = State.id
  useEffect(() => {
    let doc = document.getElementById(id)
    setTimeout(() => {
      if (doc) {
        scroll.scrollTo(doc?.offsetTop - 90, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        })
      }
      setState({ type: action.id, id: '' })
    }, 0)
  }, [id, copnayref, setState])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="company" ref={copnayref}>
      <About />
      <Mission />
      <Antifraud />
      <div className="company__mob-vers"></div>
    </div>
  )
}
