import { useTranslation } from "react-i18next"
import phons from '../image/phons.png'
import phonsmob from '../image/mobilepage/phonsmob.png'

export const Mission:React.FC=()=>{
  const { t } = useTranslation()
  return <div>
    <div className="company__mission">
          <div>
            <img src={phons} className="company__phons-img" alt="no" />
            <img src={phonsmob} className="company__phons-mob" alt="no" />
          </div>
          <div className="company__text-mob2"></div>
          <div className="company__app-info">
            <p>{t('Our_Mission')}</p>
            <p>{t('and_Vision')}</p>
            <div className="company__texts">
              <div>{t('text_dot')}</div>
            </div>
            <div className="company__li-pos">
              <ul>
                <li>{t('to_provide_f')}</li>
              </ul>
              <ul>
                <li>{t('to_provide_h')}</li>
              </ul>
              <ul>
                <li> {t('to_offer_z')}</li>
              </ul>
              <ul>
                <li>{t('to_offer_b')}</li>
              </ul>
            </div>
          </div>
        </div>
  </div>
}