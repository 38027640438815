import { useTranslation } from 'react-i18next'
import coin from '../image/ivsc.png'
import coinmob500 from '../image/mobilepage/ivscmob500.png'

export const Exchange = () => {
  let { t } = useTranslation()

  return (
    <div id="target2">
      <div className="exchange">
        <img src={coin} alt="no" className="exchange__background-img" />
        <img
          src={coinmob500}
          alt="no"
          className="exchange__background-img-mob"
        />
        <div className="exchange__shadow-exchange">
          <div className="exchange__shadow-position">
            <div className="exchange__currency-titles">
              <p className="exchange__currency-p"> {t('Currency')}</p>
              <p> {t('Exchange')}</p>
            </div>

            <div>
              <div className="exchange__text-dot-d">{t('text_dot')}</div>
            </div>
            <div className="exchange__text-info-curency">
              <p>{t('exchage_text')}</p>
              <span>{t('bold_text')}</span>
              <div>{t('with_us')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="exchange__text-info-curency-mob">
        <p>{t('exchage_text')}</p>
        <span>{t('bold_text')}</span>
        <p>{t('with_us')}</p>
      </div>
    </div>
  )
}
