import React from 'react'
import './styles/globals.scss'
import Header from './page/headerPage'
import Footer from './page/footer'
import { BrowserRouter } from 'react-router-dom'
import { Routing } from './routing/routes'
import { RecommendedMessageProvider } from './context/messgecontext'

let App: React.FC = () => {
  return (
    <div>
      <BrowserRouter>
        <RecommendedMessageProvider>
          <Header />
          <Routing />
          <Footer />
        </RecommendedMessageProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
