import { useState, useRef, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Mobile } from './headermobile'
import topmoney from '../image/icons/topmoney.svg'
import { useTranslation } from 'react-i18next'
import x from '../image/icons/x.svg'
import servic from '../image/icons/services.svg'
import bug from '../image/icons/bug.svg'
import about from '../image/icons/about.svg'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import { action } from '../models/logincontext'


const Header: React.FC = ({ children }) => {
  let [mobile, setMobil] = useState<boolean>(false)
  let [services, setServices] = useState<boolean>(false)
  let [company, setCompany] = useState<boolean>(false)
  const { t } = useTranslation()
  let mobilemenu = () => {
    return setMobil(true)
  }
  let location = useLocation()
  const { setState } = UseRecommendedLoginContext()
  let useOutsideFunction = (ref: any) => {
    useEffect(() => {
      let handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setServices(false)
          setCompany(false)
          setMobil(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  function doSomething(scroll_pos: any) {
    setServices(false)
    setCompany(false)
  }
  let last_known_scroll_position = 0
  useEffect(() => {
    window.addEventListener('scroll', function (e) {
      window.requestAnimationFrame(function () {
        doSomething(last_known_scroll_position)
      })
    })
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('scroll', doSomething)
    }
  }, [last_known_scroll_position])
  const wrapperRef = useRef(null)
  useOutsideFunction(wrapperRef)

  return (
    <div className="header">
      <div className="header__context">
        <div>
          <Link to="/home">
            <img className="header__top-moneyimg" src={topmoney} alt="no" />
          </Link>
        </div>
        <div className="header__right-menu">
          <div
            className={`${
              location.pathname === '/services'
                ? 'header__isactive'
                : 'header__focus'
            }`}
            tabIndex={0}
            onClick={() => {
              return setServices(!services), setCompany(false)
            }}
          >
            {t('Services')}
          </div>
          <div
            tabIndex={0}
            className={`${
              location.pathname === '/company'
                ? 'header__isactive'
                : 'header__focus'
            }`}
            onClick={() => {
              return setCompany(!company), setServices(false)
            }}
          >
            {t('Company')}
          </div>
        </div>
        <div className="header__mobile">
          <Mobile>{mobilemenu}</Mobile>
        </div>
      </div>

      {services && (
        <div className={'header__services-menu'} ref={wrapperRef}>
          <div>
            <Link
              to="/services"
              onClick={() => setState({ type: action.id, id: 'target1' })}
            >
              {t('Global-transfers')}
            </Link>
          </div>
          <div>
            <Link
              to="/services"
              onClick={() => setState({ type: action.id, id: 'target2' })}
            >
              {t('Currency_Exchange')}
            </Link>
          </div>
          <div>
            <Link
              to="/services"
              onClick={() => setState({ type: action.id, id: 'target3' })}
            >
              {t('Top_Money_App')}
            </Link>
          </div>
        </div>
      )}
      {company && (
        <div className={'header__company-menu'} ref={wrapperRef}>
          <div>
            <Link
              to="/company"
              onClick={() => setState({ type: action.id, id: 'target4' })}
            >
              {t('About_US')}
            </Link>
          </div>
          <div>
            <Link
              to="/company"
              onClick={() => setState({ type: action.id, id: 'target5' })}
            >
              {t('Anti_Fraud')}
            </Link>
          </div>
          <div>
            <Link to="/contact">{t('header_menu_contact_us')}</Link>
          </div>
        </div>
      )}

      {mobile && (
        <div className="header__mobile-menu" ref={wrapperRef}>
          <div>
            <img
              src={x}
              alt="loading"
              onClick={() => setMobil(false)}
              className="header__mobile-x"
            />
          </div>
          <div className="header__mobile-icon">
            <img src={servic} alt="loading" />
            <Link to="/services">{t('Services')}</Link>
          </div>

          <div className="header__mobile-icon">
            <img src={bug} alt="loading" />
            <Link to="/company">{t('Company')}</Link>
          </div>
          <div className="header__mobile-icon">
            <img src={about} alt="loading" />
            <Link to="/contact">{t('header_menu_contact_us')}</Link>
          </div>
        </div>
      )}
    </div>
  )
}
export default Header
