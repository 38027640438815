import message from '../image/icons/message.svg'

export const ThankYou: React.FC = () => {
  return (
    <div className="thankyou">
      <img className='thankyou__img-m' src={message } alt="loading" />
      <p className="thankyou__tittle">THANK YOU!</p>
      <div className="thankyou__text-dot-message">. . . . . . .</div>
      <p className="thankyou__info">Thank you for contacting us! We will get in</p>
      <p className="thankyou__info"> touch with you shortly.</p>
    </div>
  )
}
