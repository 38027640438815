import background from '../image/frame37.png'
import backgroundmob from '../image/mobilepage/framemob.png'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const HomePage: React.FC = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
   }, [])
  return (
    <div className="home">
      <div>
        <div className="home__tittle">
          <img src={background} alt="no" className="home__money-img" />
          <img src={backgroundmob} alt="no" className="home__money-img-mob" />
          <div className="home__box">
            <div className="home__shadow">
              <h1 className="home__title">{t('header_menu_help')}</h1>
              <div className="home__text">
                <p>{t('header_menu_our_relaible')}</p>
                <p>{t('header_menu_our_products')} </p>
                <p>{t('header_menu-about_trusted')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
