import { Reasons } from './reasons'
import phonebackground from '../image/icons/phone.svg'

export const Topmoney = () => {
  return (
    <div  id="target3">
      <div className="topmoney__reason">
        <img
          src={phonebackground}
          className="topmoney__background-img"
          alt="no"
        />
        <div className="topmoney__app9">
          <Reasons />
        </div>
       
      </div>
    </div>
  )
}
