import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const Privacy: React.FC = () => {
  const { t } = useTranslation()
  useEffect(()=>{window.scrollTo(0, 0)},[])
  return (
    <div className="privacy" id="target7">
      <div className="privacy__policy">
        <div>
          <h1 className="privacy__title">{t('Privecy_Policy')}</h1>
          <div>
            <div className="privacy__textk">{t('text_dot')}</div>
          </div>
          <div className="privacy__text">
            <p>{t('Last_updated')}</p>
            <p>{t('this_privacy')}</p>
            <p>{t('we_use')}</p>
            <h1>{t('interpretation_u')}</h1>
            <h3>{t('Interpretation')}</h3>
            <p>{t('The_words')}</p>
            <h3>{t('Definitions')}</h3>
            <p>{t('For_the')}</p>
            <p>{t('account')}</p>
            <h1>{t('Collecting')}</h1>
            <h3>{t('Types of Data Collected')}</h3>
            <h4>{t('Personal_Data')}</h4>
            <div>
              <p>{t('Email_address')}</p>
              <p>{t('First_name')}</p>
              <p>{t('Phone_number')}</p>
              <p>{t('Usage_Data')}</p>
            </div>
            <h4>{t('Usage_Data')}</h4>
            <p>{t('Usage_Data_is_collected ')}</p>
            <p>{t('USAGE_data_text-i')}</p>
            <h4>{t('Cookies')}</h4>
            <p>{t('Cookies-text')}</p>
            <ul>
              <li>{t('browser')}</li>
              <li>
                {t('flash')}
                <p>
                  https://helpx.adobe.com/flash-player/kb/disable-local-shared<br/>-objects-flash.html<br/>#main_Where_
                  <br />
                </p>
              </li>
              <li>{t('beacons')}</li>
            </ul>
            <p>{t('pers')}</p>
            <div>
              <h4>{t('Nec')}</h4>
              <p>{t('necessary')}</p>
            </div>
            <div>
              <h4>{t('Cookies_Policy')}</h4>
              <p>{t('uscookies')}</p>
            </div>
            <div>
              <h4>{t('Functionality_Cookies')}</h4>
              <p>{t('uscookies3')}</p>
              <h4>{t('useof')}</h4>
              <p>{t('Use_Data')}</p>
            </div>
            <ul>
              <li>{t('withsp')}</li>
              <li>{t('fbt')}</li>
              <li>{t('waw')}</li>
              <li>{t('wbt')}</li>
              <li>{t('wou')}</li>
              <li>{t('wyc')}</li>
            </ul>
            <h3>{t('roy')}</h3>
            <p>{t('tcwry')}</p>
            <h3>{t('Transfer')}</h3>
            <p>{t('yourinf')}</p>
            <h3>{t('Disclosure_Data')}</h3>
            <p>{t('btranfers')}</p>
            <p>{t('law_enf')}</p>
            <p>{t('olr')}</p>
            <ul>
              <li>{t('comply')}</li>
              <li>{t('defend')}</li>
              <li>{t('investigate')}</li>
              <li>{t('personal')}</li>
              <li>{t('against')}</li>
            </ul>
            <h4>{t("security")}</h4>
            <p>{t('tsyourpersonal')}</p>
           
            <h1>{t("Children_Privacy")}</h1>
            <p>{t("children_text")}</p>
            <h1>{t("link_website")}</h1>
            <p>{t("link_website_text")}</p>
            <h1>{t("changes_to")}</h1>
            <p>{t("changes_t-text")}</p>
            <h1>{t("Contact_Us")}</h1>
            <p>{t("IFYOUHAVE")}</p>
            <ul>
              <li>{t("LIBYVISITING")}</li>
            </ul>
          </div>
          <div className="privacy__text">
            <p></p>
          </div>
        </div>
      </div>
    </div>
  )
}
