import { useTranslation } from 'react-i18next'

export const Globalmoneycoutry: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div className="services__country">
        <div> {t('SRI_lanka')}</div>
        <div> {t('Thailand')}</div>
        <div> {t('India')}</div>
        <div> {t('Philippines')}</div>
        <div>{t('Georgia')} </div>
        <div>{t('China')} </div>
        <div>{t('Moldova')} </div>
        <div> {t('Russia')}</div>
        <div>{t('Vietnam')} </div>
        <div>{t('Turkey')} </div>
        <div>{t('EU')} </div>
        <div>{t('Armenia')} </div>
        <div>{t('Kazakhstan')} </div>
        <div>{t('Ukraine')}</div>
      </div>
    </div>
  )
}
