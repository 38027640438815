import { Routes, Route } from 'react-router'
import { publicroute } from './Routing'

export const Routing: React.FC = () => {
  return (
    <div>
      <Routes>
        {publicroute.map((u) => (
          <Route key={u.path} path={u.path} element={<u.element />} />
        ))}
      </Routes>
    </div>
  )
}
