import { Globalmoneycoutry } from './globalmoney'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
const video = require('../video/map.mp4')

export const Send = () => {
  const { t } = useTranslation()
  useEffect(() => {
   window.scrollTo(0, 100)
  }, [])
  return (
    <div id="target1">
      <div className="services">
        <div className="services__info">
          <h1 className="services__title">
            <p>{t('Global_Money')}</p>
            <p>{t('t')}</p>
          </h1>
          <div className="services__text-dot">
            <div>{t('text_dot')}</div>
          </div>
          <div className="services__text-info">{t('Global_money_text')}</div>
          <Globalmoneycoutry />
        </div>
        <div>
          <div>
            <video
              className="services__video-sz"
              autoPlay
              playsInline
              muted
              loop
            >
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  )
}
