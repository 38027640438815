import { message } from './../models/api'
const { REACT_APP_API_ENDPOINT } = process.env

export const fetchmessage = async (datastate: message) => {
  const url = `${REACT_APP_API_ENDPOINT}/contactUs`
  const data = datastate

  try {
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      mode: 'cors',
    })
  } catch (error) {
    console.error('Ошибка:', error)
  }
}
