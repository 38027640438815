import { useRef, useEffect } from 'react'
import { Send } from './send'
import { Exchange } from './exchange'
import { Topmoney } from './topmoney'
import { UseRecommendedLoginContext } from '../context/messgecontext'
import { action } from '../models/logincontext'
import { animateScroll as scroll } from 'react-scroll'

export const Services = () => {
  const { State, setState } = UseRecommendedLoginContext()
  const wrapperRef = useRef(null)
  let id = State.id
  useEffect(() => {
    let doc = document.getElementById(id)
    setTimeout(() => {
      // doc && doc.scrollIntoView({block:"center"})

      if (doc) {
        scroll.scrollTo(doc?.offsetTop - 90, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        })
      }
      setState({ type: action.id, id: '' })
    }, 0)
  }, [id, wrapperRef, setState])

  return (
    <div ref={wrapperRef}>
      <Send />
      <Exchange />
      <Topmoney />
    </div>
  )
}
