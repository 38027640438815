import message from '../image/message.png'
import loc from '../image/icons/locationIcon.svg'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { Contactform } from '../components/Contactform'
import { ThankYou } from './ThankyouPage'
import { UseRecommendedLoginContext } from '../context/messgecontext'

export const Contact: React.FC = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  let { State } = UseRecommendedLoginContext()
  return (
    <div className="contact">
      <div>
        <div>
          <img src={message} alt="no" className="contact__message-img" />
        </div>
        <div className="contact__mesage-mob-vers"></div>
        <div className="contact__info-pos">
          <div className="contact__info">
            <h1>{t('Contact_us')}</h1>
            <div>
              <div className="contact__texts-dot">{t('text_dot')}</div>
            </div>
            <div>
              <p className="contact__text-info-c">{t('App-top_money_text')}</p>
            </div>
            <div className="contact__loc-pos">
              <div className="contact__lock">
                <img src={loc} alt="no" />
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.google.com/maps/place/King+George+1,+Tel+Aviv-Yafo,+%D0%98%D0%B7%D1%80%D0%B0%D0%B8%D0%BB%D1%8C/@32.0700336,34.7684242,17z/data=!3m1!4b1!4m5!3m4!1s0x151d4c816552705f:0x1005a53c1e2cd45e!8m2!3d32.0700336!4d34.7706129"
                >
                  {t('path_1')}
                </a>
              </div>
              <div className="contact__locl">
                <img src={loc} alt="no" />
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.google.com/maps/place/Levinski+96,+Tel+Aviv-Yafo,+%D0%98%D0%B7%D1%80%D0%B0%D0%B8%D0%BB%D1%8C/@32.0573839,34.7758492,17z/data=!3m1!4b1!4m5!3m4!1s0x151d4b63def4e31d:0xb6df375b918d103c!8m2!3d32.0573794!4d34.7780379"
                >
                  {t('path_2')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact__input-bg">
        <div className="contact__question-pos"></div>
        <div className="contact__input">
          {State.isAuth ? (
            <div>
              <ThankYou />
            </div>
          ) : (
            <div>
              <Contactform />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
