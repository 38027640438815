import React, { createContext, useContext, useMemo, useReducer } from 'react'
import { Action, Statetype, initialState } from '../models/logincontext'

function LoginReducer(state: any, action: Action) {
  switch (action.type) {
    case 'isAuth':
      return { ...state, isAuth: action.isAuth }

    case 'isLoading':
      return {
        ...state,
        isLoading: action.isLoading,
      }

    case 'shadow':
      return {
        ...state,
        shadow: action.shadow,
      }
    case 'cookie':
      return {
        ...state,
        cookie: action.cookie,
      }
    case 'navigate':
      return {
        ...state,
        navigationTarget: action.navigationTarget,
      }
    case 'id':
      return {
        ...state,
        id: action.id,
      }
    default:
      return state
  }
}

const initialLoginStateContext: {
  State: Statetype
  setState: React.Dispatch<Action>
} = {
  State: initialState,
  setState: () => ({}),
}
const LoginBudgeContext = createContext(initialLoginStateContext)

export function RecommendedMessageProvider({
  children,
}: {
  children?: JSX.Element | JSX.Element[]
}) {
  const [State, setState] = useReducer(LoginReducer, initialState)
  const contextValues = useMemo(() => ({ State, setState }), [State])
  return (
    <>
      <LoginBudgeContext.Provider value={contextValues}>
        {children}
      </LoginBudgeContext.Provider>
    </>
  )
}

export const UseRecommendedLoginContext = () => useContext(LoginBudgeContext)
